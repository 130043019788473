<template>
  <div>
    <v-card
      flat
    >
      <v-toolbar dense dark color="primary">
        <v-toolbar-title><h4 class="font-weight-light">BANK TRANSACTION SUMMARY</h4>
        </v-toolbar-title>
      </v-toolbar>
      <v-row>
        <v-col
          md="12"
          cols="12"
        ></v-col>
        <v-col
          md="6"
          cols="12"
        >
          <v-select
            v-model="month_of"
            class="mx-3"
            :items="month_of_items"
            item-value="id"
            item-text="month_of"
            label="Month Of"
            required
            @change="monthly_remittances"
            dense
          ></v-select>
        </v-col>
      </v-row>
      <v-data-table dense
                    :headers="headers"
                    :items="collectors_data"
      >
        <template v-slot:item="{ item }">
          <tr
            @click="activerow(item)"
            :class="{'info black--text': item.id===selectedDepositId}"
          >
            <td>{{ item.id }}</td>
            <td>
              {{ item.bank_code_name}}
            </td>
            <td>
              {{ item.account_no}}
            </td>
            <td>
              {{ formatPrice(item.amount) }}
            </td>
          </tr>
        </template>
      </v-data-table>
    </v-card>
    <snack-bar-dialog
      :snackbar_flag="this.snackbar"
      :color="this.snackbar_color"
      :snackbar_text="this.snackbar_text"
    />
  </div>
</template>

<script>
  import {mdiPrinter, mdiDelete, mdiEyeOutline, mdiToggleSwitch} from '@mdi/js'
  import {mapGetters, mapActions} from "vuex";
  import snackBarDialog from '@/components/dialogs/notifications_dialog/Snackbar'
  import moment from 'moment'

  export default {
    components: {
      snackBarDialog,
    },
    mounted() {
      const data = new FormData()
      data.append('branch_id', this.branch_id);
      this.get_transaction_month_history(data)
        .then(response => {
          this.month_of_items = response.data
        })
        .catch(error => {
          console.log(error)
        })
    },
    setup() {
      return {
        icons: {
          mdiPrinter,
          mdiDelete,
          mdiEyeOutline,
          mdiToggleSwitch,
        },
      }
    },
    data() {
      return {
        headers: [
          {text: 'ID', value: 'id', sortable: false},
          {text: 'BANK CODE', value: 'date_of_deposit', sortable: false},
          {text: 'ACCOUNT #', value: 'time_of_deposit', sortable: false},
          {text: 'AMOUNT', value: 'amount', sortable: false},],

        data: [],
        bank_depositories: [],
        collectors_data: [],
        collectors_remittances_data: [],

        month_of_items: [],
        month_of: '',
        selectedDepositId: -1,
      }
    },
    computed: {
      ...mapGetters('authentication', ['position', 'branch_id', 'branch_contact_no', 'branch_address', 'branch','branch_id_selected']),
      ...mapGetters('system_data', ['snackbar', 'snackbar_color', 'snackbar_text', 'company_logo']),
    },
    methods: {
      ...mapActions('system_data', ['change_snackbar']),
      ...mapActions('transaction_months_history', ['get_transaction_month_history']),
      ...mapActions('deposit_slip', ['bank_transaction_summary']),
      date_format(value) {
        return moment(value)
      },
      formatPrice(value) {
        let val = (value / 1).toFixed(2).replace(',', '.')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      },
      monthly_remittances() {
        var index = this.month_of_items.map(function (x) {
          return x.id;
        }).indexOf(this.month_of)
        if (index != -1) {
          const data = new FormData()
          data.append('branch_id', this.branch_id===1?this.branch_id_selected:this.branch_id);
          data.append('month_of', this.month_of_items[index].month_of);
          this.bank_transaction_summary(data)
            .then(response => {
              this.selectedDepositId = -1
              this.collectors_data = response.data
              this.collectors_remittances_data = []
            })
            .catch(error => {
              console.log(error)
            })
        }
      },
      activerow(value) {
        this.data = value
        this.bank_depositories = value.bank_depositories
        this.selectedDepositId = value.id;
        this.collectors_remittances_data = value.liquidations
      },
    }
  }
</script>
